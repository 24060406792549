import { createStore } from 'vuex';

import Auth from '@aws-amplify/auth';
import { apiEndpoint } from './awsconfig.js';
import { getItem, setItem, removeItem } from './localStorage.js';

let sessionTimer;

const startSessionTimer = (context, expiration) => {
  console.debug('startSessionTimer called with', expiration);

  const { alreadyExpired, expiresIn } = getRemainingTime(expiration);
  if (alreadyExpired) {
    console.debug('session already expired');
    return;
  }

  sessionTimer = setTimeout(function () {
    context.dispatch('logout');
  }, expiresIn);

  console.debug('session timer set', sessionTimer);
};

const getRemainingTime = (expiration) => {
  console.debug('getRemainingTime called with', expiration);

  let expiresIn = -1;
  let alreadyExpired = true;

  if (expiration) {
    expiresIn = +expiration - new Date().getTime();
    console.debug('expiresIn', expiresIn);
    if (expiresIn > 0) {
      alreadyExpired = false;
    } else {
      console.debug('expiration already expired');
    }
  } else {
    console.debug('expiration falsey');
  }

  const returnValue = {
    alreadyExpired,
    expiresIn
  };
  console.debug('remaining time', returnValue);
  return returnValue;
};

const clearSessionTimer = () => {
  clearTimeout(sessionTimer);
  console.debug('session timer cleared');
};

const newUser = () => {
  return {
    //My cognito userpool configuration does not allow for users to define their own username,
    //so this is internally generated by cognito. Same as the 'subject'.
    userid: null,
    //save pwd for new user, to auto-login after confirmation. yuck.
    password: null,
    email: null,
    verified: false,
    accessToken: null,
    idToken: null,
    expiration: null,
    //split this into another module
    archiveList: null,
    archiveSize: 0
  };
};

const userFromCognitoUser = (cognitoUser) => {
  console.debug('building user from cognito user', cognitoUser);

  const user = newUser();

  user.userid = cognitoUser.username;

  const hoursUntilExpiration = 32;
  const expiration = new Date();
  expiration.setHours(new Date().getHours() + hoursUntilExpiration);
  user.expiration = expiration.getTime();

  const cogSession = cognitoUser.getSignInUserSession();
  if (cogSession) {
    user.accessToken = cogSession.getAccessToken().getJwtToken();
    user.idToken = cogSession.getIdToken().getJwtToken();
  } else {
    console.error('no cognito session', cognitoUser);
    user.accessToken = null;
    user.idToken = null;
  }

  if (cognitoUser.attributes) {
    user.email = cognitoUser.attributes['email'];
    user.verified = cognitoUser.attributes['email_verified'];
  } else {
    console.error('no user attributes', cognitoUser);
    user.email = '';
    user.verified = false;
  }

  console.debug('returning user', user);
  return user;
};

export default createStore({
  state() {
    return newUser();
  },
  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    idToken(state) {
      return state.idToken;
    },
    password(state) {
      return state.password;
    },
    isAuthenticated(state) {
      return !!state.accessToken;
    },
    email(state) {
      return state.email;
    },
    archiveList(state) {
      return state.archiveList;
    },
    archiveSize(state) {
      return state.archiveSize;
    }
  },
  mutations: {
    password(state, pwd) {
      state.password = pwd;
    },
    clearPassword(state) {
      state.password = null;
      console.debug('password cleared');
    },
    user(state, user) {
      console.debug('user commit called', user);

      state.accessToken = user.accessToken;
      state.idToken = user.idToken;
      state.email = user.email;
      state.verified = user.verified;
      state.userid = user.userid;
      state.expiration = user.expiration;

      if (user.password) {
        state.password = user.password;
      } else {
        state.password = null;
      }

      console.debug('user commit done');
    },
    archiveList(state, list) {
      console.debug('archiveList commit called', list);
      state.archiveList = list;
      console.debug('archiveList commit done');
    },
    archiveSize(state, size) {
      state.archiveSize = size;
    }
  },
  actions: {
    async login(context, data) {
      console.debug('login called with', data);

      try {
        const cognitoUser = await Auth.signIn(
          data.formEmail,
          data.formPassword
        );
        console.debug('login result', cognitoUser);
        const user = userFromCognitoUser(cognitoUser);

        setItem('accessToken', user.accessToken);
        setItem('idToken', user.idToken);
        setItem('email', user.email);
        setItem('verified', user.verified);
        setItem('userid', user.userid);
        setItem('expiration', user.expiration);

        startSessionTimer(context, user.expiration);

        context.commit('user', user);
      } catch (error) {
        console.error('login error', error);
        throw error;
      }

      console.debug('login done');
    },
    async logout(context) {
      console.debug('logout called');

      try {
        //signout does not return a result.
        await Auth.signOut();
        console.debug('signout returned');
      } catch (error) {
        console.error('signout error', error);
        throw error;
      }

      removeItem('accessToken');
      removeItem('idToken');
      removeItem('email');
      removeItem('verified');
      removeItem('userid');
      removeItem('expiration');

      clearSessionTimer();

      context.commit('user', newUser());

      console.debug('logout done');
    },
    async autoLogin(context) {
      console.debug('autoLogin called');
      const accessToken = getItem('accessToken');

      if (!accessToken) {
        //the existence of this key defines whether there is a saved session or not.
        console.debug('autoLogin aborted, access token is null');
        return;
      }

      const expiration = getItem('expiration');
      const { alreadyExpired } = getRemainingTime(expiration);
      if (alreadyExpired) {
        console.debug('autoLogin aborted, session is expired');
        return;
      }

      const idToken = getItem('idToken');
      const email = getItem('email');
      const verified = getItem('verified');
      const userid = getItem('userid');

      context.commit('user', {
        accessToken,
        idToken,
        email,
        verified,
        userid,
        expiration
      });

      startSessionTimer(context, expiration);

      console.debug('autoLogin done');
    },
    async create(context, data) {
      console.debug('create called with', data);
      const params = {
        username: data.formEmail,
        password: data.formPassword
        // attributes: {
        //   email: this.email
        // }
      };

      try {
        const { user } = await Auth.signUp(params);
        console.debug('create result', user);
        //Kludge alert!
        //Cognito requires the user to login again after confirmation.
        //Do the login for them behind the scenes.
        context.commit('password', data.formPassword);
      } catch (error) {
        console.error('create error', error);
        throw error;
      }

      console.debug('create done');
    },
    async confirm(context, data) {
      console.debug('confirm called with', data);
      try {
        const result = await Auth.confirmSignUp(
          data.formEmail,
          data.formConfirmationCode
        );
        //Kludge alert! - auto login after confirmation
        if (result === 'SUCCESS') {
          await context.dispatch('login', {
            formEmail: data.formEmail,
            formPassword: context.getters.password
          });
        } else {
          let msg = 'confirmSignUp returned unexpected result ' + result;
          console.error(msg);
          throw new Error(msg);
        }
      } catch (error) {
        console.error('confirm error', error);
        throw error;
      } finally {
        context.commit('clearPassword');
      }

      console.debug('confirm done');
    },
    /**
     * @param {*} context
     * @param {*} data map containing 'url' and 'desc' keys.
     */
    async archive(context, data) {
      console.debug('archive called with', data);
      try {
        const apiURL = `${apiEndpoint}/archive2?accessToken=${context.getters.accessToken}`;
        console.debug('archive POST', apiURL);

        const response = await fetch(apiURL, {
          method: 'POST',
          headers: {
            //prettier-ignore
            'Authorization': context.getters.idToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            url: data.url,
            desc: data.desc
          })
        });

        console.debug('archive response', response);

        if (response.ok) {
          const json = await response.json();
          console.debug('archive response msg', json);
          console.debug('archive done');
          return json;
        } else {
          console.error(
            'archive throwing error due to status',
            response.status,
            response.statusText
          );
          throw new Error(response.status + ': ' + response.statusText);
        }
      } catch (error) {
        console.error('archive error', error);
        throw error;
      }
    },
    async loadArchive(context) {
      console.debug('loadArchive called');
      try {
        const apiURL = `${apiEndpoint}/list/all?accessToken=${context.getters.accessToken}`;
        console.debug('list GET', apiURL);

        const response = await fetch(apiURL, {
          method: 'GET',
          headers: {
            //prettier-ignore
            'Authorization': context.getters.idToken,
            'Content-Type': 'application/json'
          }
        });

        console.debug('list response', response);

        if (response.ok) {
          const json = await response.json();
          console.debug('list response msg', json);
          if (json.data) {
            context.commit('archiveList', json.data);
            context.commit('archiveSize', json.count);
          } else {
            console.debug('list returned empty items list');
          }
        } else {
          console.error(
            'loadArchive throwing error due to list status',
            response.status,
            response.statusText
          );
          throw new Error(response.status + ': ' + response.statusText);
        }
      } catch (error) {
        console.error('loadArchive error', error);
        throw error;
      }

      console.debug('loadArchiveList done');
    }
  }
});
